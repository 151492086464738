import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Device",
    // component: Home,
    component: () => import("../views/device.vue"),
  },
  // {
  //   path: "/device",
  //   name: "Device",
  //   component: () => import("../views/device.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
